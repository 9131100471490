import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import RedirectWithQuery from 'RedirectWithQuery';
import snackbar from 'snackbar/snackbar';
import PrivateRoute from 'private-route/PrivateRoute';
import CircularLoader from 'components/Loaders/CircularLoader';
import Page from 'components/Page';
import { selectLoading, selectProgress } from 'components/Loaders/loadingSlice';
import Permission from 'features/Permission';
import GlobalLoader from 'components/Loaders/GlobalLoader';
import OfflineFallback from 'components/OfflineFallback';
import ErrorBoundary from 'components/ErrorBoundaryClasses/ErrorBoundary';
import NotFoundPage from 'components/NotFoundPage';
import { useTheme } from '@mui/material/styles';
import InvitationExpired from 'pages/auth/invitation-expired/ExpiredInvitation';
import { selectPdfDownloadUrl, handlePopupBlocked } from 'pages/robotpage/components/overview/smartReportSlice';
import { selectUserPermission, getUserPermission, selectUserPermissionLoading } from 'pages/auth/permissionSlice';
import { selectUserDetails, selectUserProfile, logout } from 'pages/auth/authSlice';
import { getUserTeams, selectUserTeams, selectUserTeamsLoading } from 'pages/management/components/teams/teamSlice';
import BrowserWarning from 'BrowserWarning';
import { setInstallationParams } from 'reducers/appSlice';
import { useMediaQuery } from '@mui/material';

const Login = React.lazy(() => import('pages/auth/Signin/Login'));
const Logout = React.lazy(() => import('pages/auth/Signout/Logout'));
const Verify = React.lazy(() => import('pages/auth/Signin/Verify'));
const ForgetPassword = React.lazy(() => import('pages/auth/Signin/ForgetPassword'));
const ResetPassword = React.lazy(() => import('pages/auth/Signin/ResetPassword'));
const Signup = React.lazy(() => import('pages/auth/Signup'));
const Invite = React.lazy(() => import('pages/auth/Invite'));
const EmailVerification = React.lazy(() => import('pages/auth/EmailVerification/EmailVerification'));
const EmailVerified = React.lazy(() => import('pages/auth/EmailVerification/EmailVerified'));
const Dashboard = React.lazy(() => import('pages/dashboard/Dashboard'));
const Robotops = React.lazy(() => import('pages/robotops'));
const Tickets = React.lazy(() => import('pages/tickets'));
const Ticket = React.lazy(() => import('pages/ticket'));
const Alerts = React.lazy(() => import('pages/alerts'));
const Alert = React.lazy(() => import('pages/alert'));
const EventHistory = React.lazy(() => import('pages/ticket/EventHistory'));
const FMImageAnnotator = React.lazy(() => import('pages/ticket/FMImageAnnotator'));
const TicketIndex = React.lazy(() => import('pages/ticket/TicketIndex'));
const Comments = React.lazy(() => import('pages/ticket/Comments'));
const RobotPage = React.lazy(() => import('pages/robotpage'));
const RobotStream = React.lazy(() => import(
  'pages/robotpage/components/pilot/pilotcamera/PilotMultiCameraPopout/RobotStream'
));
const PilotCameraPopout = React.lazy(() => import('pages/robotpage/components/pilot/pilotcamera/PilotCameraPopout'));
const Overview = React.lazy(() => import('pages/robotpage/components/overview'));
const EventLog = React.lazy(() => import('pages/robotpage/components/eventlog'));
const Pilot = React.lazy(() => import('pages/robotpage/components/pilot'));
const PilotOnsite = React.lazy(() => import('pages/robotpage/components/pilot-onsite'));
const Terminal = React.lazy(() => import('pages/robotpage/components/terminal'));
const TopicMonitor = React.lazy(() => import('pages/robotpage/components/topic-monitor'));
const Missions = React.lazy(() => import('pages/robotpage/components/missions'));
const Config = React.lazy(() => import('pages/robotpage/components/config'));
const InstallationTab = React.lazy(() => import('pages/robotpage/components/config/components/Installation'));
const TopicConfiguratorTab = React.lazy(() => import('pages/robotpage/components/config/components/TopicConfigurator'));
const PropertiesTab = React.lazy(() => import('pages/robotpage/components/config/components/Properties/Properties'));
const MapTab = React.lazy(() => import('pages/robotpage/components/config/components/Map/Map'));
const CameraTab = React.lazy(() => import('pages/robotpage/components/config/components/CameraSources/CameraSources'));
const Settings = React.lazy(() => import('pages/settings'));
const AccountsTab = React.lazy(() => import('pages/settings/components/Accounts'));
const SecurityTab = React.lazy(() => import('pages/settings/components/Security'));
const NotificationsTab = React.lazy(() => import('pages/settings/components/Notifications'));
const ThemesTab = React.lazy(() => import('pages/settings/components/Themes'));
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));
const Management = React.lazy(() => import('pages/management'));
const Teams = React.lazy(() => import('pages/management/components/teams/Teams'));
const Groups = React.lazy(() => import('pages/management/components/groups/Groups'));
const UsersList = React.lazy(() => import('pages/management/components/user/UsersList'));
const RobotsList = React.lazy(() => import('pages/management/components/robot/RobotsList'));
const PrivacyPolicy = React.lazy(() => import('pages/settings/PrivacyPolicy'));
const VerifyEmbed = React.lazy(() => import('./VerifyEmbed'));
const DataExplorer = React.lazy(() => import('pages/robotpage/components/dataexplorer'));

function App() {
  const isLoading = useSelector(selectLoading);
  const userDetails = useSelector(selectUserDetails);
  const userprofile = useSelector(selectUserProfile);
  const permissions = useSelector(selectUserPermission);
  const progressValue = useSelector(selectProgress);
  const pdfDownloadUrl = useSelector(selectPdfDownloadUrl);
  const [currentTeam, setCurrentTeam] = useState('');
  const permissionsLoading = useSelector(selectUserPermissionLoading);
  const userTeams = useSelector(selectUserTeams);
  const userTeamsLoading = useSelector(selectUserTeamsLoading);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (userDetails?.userId) {
      if (permissions?.length === 0 && !permissionsLoading && permissions !== null) {
        dispatch(getUserPermission({ userId: userDetails?.userId }));
      } else if (permissions === null) {
        dispatch(logout());
      }
      if (userTeams?.length === 0 && !userTeamsLoading) dispatch(getUserTeams({ userId: userDetails?.userId }));
    }
  }, [userDetails, permissions, permissionsLoading, userTeams, userTeamsLoading]);

  useEffect(() => {
    if (pdfDownloadUrl) {
      const newWindow = window.open(pdfDownloadUrl, '_blank', 'noopener,noreferrer');
      if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
        dispatch(handlePopupBlocked(pdfDownloadUrl));
      }
    }
  }, [pdfDownloadUrl]);

  useEffect(() => {
    if (userprofile?.team?.team_name) {
      setCurrentTeam(userprofile?.team?.team_name);
    }
  }, [userprofile]);

  useLayoutEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      if (!isMobileScreen || !sessionStorage.getItem('installPromptDismissed')) {
        dispatch(setInstallationParams({ deferredPrompt: e, showInstallButton: true }));
      }
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  snackbar();
  return (
    <BrowserRouter>
      {isLoading && <GlobalLoader currentProgress={progressValue} />}
      <BrowserWarning />
      <React.Suspense fallback={<CircularLoader />}>
        <Routes>
          <Route
            path="/not-found"
            element={
              <NotFoundPage theme={theme} />
            }
          />
          <Route
            path="/invite"
            exact={true}
            element={<Page component={<Invite />} title="Invitation" />}
          />
          <Route
            path="/invite-expired"
            element={
              <Page
                component={<InvitationExpired />}
                title="Invitation expired"
              />
            }
          />
          <Route
            path="/email-verification"
            element={
              <Page
                component={<EmailVerification />}
                title="Verifying Email..."
              />
            }
          />
          <Route
            path="/email-verified"
            element={
              <Page component={<EmailVerified />} title="Email Verified" />
            }
          />
          <Route
            exact={true}
            path="/login"
            element={
              <Page component={<Login />} title="Login Kabam Robotics" />
            }
          />
          <Route exact={true} path="/logout" element={<Logout />} />
          <Route exact={true} path="/login/verify" element={<Verify />} />
          <Route
            exact={true}
            path="/login/forgetpassword"
            element={<ForgetPassword />}
          />
          <Route
            exact={true}
            path="/resetpassword"
            element={<ResetPassword />}
          />
          <Route
            exact={true}
            path="/signup"
            element={
              <Page component={<Signup />} title="SignUp Kabam Robotics" />
            }
          />
          <Route exact={true} element={<PrivateRoute />}>
            <Route path="robotstream/:team_code">
              <Route
                path=":robot_code"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <Permission permissionName="robotops:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <RobotPage
                              defaultFullscreen={true}
                              defaultRobotAccess={true}
                            />
                          </React.Suspense>
                        </Permission>
                      }
                    />
                  </ErrorBoundary>
                }
              >
                <Route
                  path=":selectedScreen"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <Permission permissionName="robotops:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <PilotCameraPopout />
                            </React.Suspense>
                          </Permission>
                        }
                      />
                    </ErrorBoundary>
                  }
                />
              </Route>
            </Route>
            <Route
              path="/"
              element={
                <ErrorBoundary theme={theme}>
                  <React.Suspense fallback={<CircularLoader />}>
                    <DefaultLayout />
                  </React.Suspense>
                </ErrorBoundary>
              }
            >
              <Route
                path="robotstreams"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <Permission permissionName="robotops:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <RobotStream />
                          </React.Suspense>
                        </Permission>
                      }
                      title="Robotstreams"
                    />
                  </ErrorBoundary>
                }
              />
              <Route
                path="dashboard"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <Permission permissionName="dashboard:visit">
                          <OfflineFallback>
                            <React.Suspense fallback={<CircularLoader />}>
                              <Dashboard />
                            </React.Suspense>
                          </OfflineFallback>
                        </Permission>
                      }
                      title={`Dashboard - ${currentTeam}`}
                    />
                  </ErrorBoundary>
                }
              />
              <Route
                path="robotops"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <Permission permissionName="robotops:visit">
                          <OfflineFallback showMessage={true}>
                            <React.Suspense fallback={<CircularLoader />}>
                              <Robotops />
                            </React.Suspense>
                          </OfflineFallback>
                        </Permission>
                      }
                      title={`RobotOps - ${currentTeam}`}
                    />
                  </ErrorBoundary>
                }
              />
              <Route path="robot/:team_code">
                <Route
                  path=":robot_code"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <Permission permissionName="robotops:visit">
                            <React.Suspense fallback={<CircularLoader />}>
                              <RobotPage />
                            </React.Suspense>
                          </Permission>
                        }
                        title={`Robot Page - ${currentTeam}`}
                      />
                    </ErrorBoundary>
                  }
                >
                  <Route index={true} element={<Navigate to="overview" />} />
                  <Route
                    path="overview"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Overview />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="dataexplorer"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="data_explorer:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <DataExplorer />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="eventlog"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="event_logs:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <EventLog />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="pilot"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Pilot />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="pilot-onsite"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="pilot_onsite:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <PilotOnsite />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="topic-monitor"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="topic_monitor:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <TopicMonitor />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="terminal"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="terminal:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Terminal />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="missions"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="missions:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Missions />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="config"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="config:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Config />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                    }
                  >
                    <Route
                      index={true}
                      element={<Navigate to="installation" />}
                    />
                    <Route
                      path="installation"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="installation:visit" isGlobal={false}>
                            <React.Suspense fallback={<CircularLoader />}>
                              <InstallationTab />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="topic-configurator"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="topic_configurator:visit" isGlobal={false}>
                            <React.Suspense fallback={<CircularLoader />}>
                              <TopicConfiguratorTab />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    />
                    <Route path="map">
                      <Route
                        index={true}
                        element={
                          <ErrorBoundary theme={theme}>
                            <Permission permissionName="config_map:visit" isGlobal={false}>
                              <React.Suspense fallback={<CircularLoader />}>
                                <MapTab />
                              </React.Suspense>
                            </Permission>
                          </ErrorBoundary>
                          }
                      />
                      <Route
                        path=":mapId"
                        element={
                          <ErrorBoundary theme={theme}>
                            <Permission permissionName="config_map:visit" isGlobal={false}>
                              <React.Suspense fallback={<CircularLoader />}>
                                <MapTab />
                              </React.Suspense>
                            </Permission>
                          </ErrorBoundary>
                          }
                      />
                    </Route>
                    <Route
                      path="properties"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="config_properties:visit" isGlobal={false}>
                            <React.Suspense fallback={<CircularLoader />}>
                              <PropertiesTab />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                      }
                    />
                    <Route
                      path="camera"
                      element={
                        <ErrorBoundary theme={theme}>
                          <Permission permissionName="camera_setting:visit" isGlobal={false}>
                            <React.Suspense fallback={<CircularLoader />}>
                              <CameraTab />
                            </React.Suspense>
                          </Permission>
                        </ErrorBoundary>
                          }
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path="tickets"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <Permission permissionName="tickets:visit">
                          <OfflineFallback>
                            <React.Suspense fallback={<CircularLoader />}>
                              <Tickets />
                            </React.Suspense>
                          </OfflineFallback>
                        </Permission>
                      }
                      title={`Tickets - ${currentTeam}`}
                    />
                  </ErrorBoundary>
                }
              >
                <Route path=":team_id/:ticket_id">
                  <Route
                    path="eventHistory"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <EventHistory />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="comments"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Comments />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="facilityManagement"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <FMImageAnnotator />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                </Route>
                <Route
                  index={true}
                  element={
                    <ErrorBoundary theme={theme}>
                      <React.Suspense fallback={<CircularLoader />}>
                        <TicketIndex />
                      </React.Suspense>
                    </ErrorBoundary>
                  }
                />
              </Route>
              <Route path="ticket">
                <Route
                  path=":team_id/:ticket_id"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Page
                        component={
                          <Permission permissionName="tickets:visit">
                            <OfflineFallback>
                              <React.Suspense fallback={<CircularLoader />}>
                                <Ticket />
                              </React.Suspense>
                            </OfflineFallback>
                          </Permission>
                        }
                        title={`Ticket Page - ${currentTeam}`}
                      />
                    </ErrorBoundary>
                  }
                >
                  <Route
                    path="eventHistory"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <EventHistory />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="comments"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Comments />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                  <Route
                    path="facilityManagement"
                    element={
                      <ErrorBoundary theme={theme}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <FMImageAnnotator />
                        </React.Suspense>
                      </ErrorBoundary>
                    }
                  />
                </Route>
              </Route>
              <Route
                path="alerts"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <OfflineFallback>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Alerts />
                          </React.Suspense>
                        </OfflineFallback>
                      }
                      title={`Alerts - ${currentTeam}`}
                    />
                  </ErrorBoundary>
                }
              />
              <Route
                path="alerts/:team_id/:alert_id"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <OfflineFallback>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Alerts />
                          </React.Suspense>
                        </OfflineFallback>
                      }
                      title={`Alerts - ${currentTeam}`}
                    />
                  </ErrorBoundary>
                }
              />
              <Route
                path="alert/:team_id/:alert_id"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <OfflineFallback>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Alert />
                          </React.Suspense>
                        </OfflineFallback>
                      }
                      title={`Alerts - ${currentTeam}`}
                    />
                  </ErrorBoundary>
                }
              />
              <Route
                path="management"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <OfflineFallback>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Management />
                          </React.Suspense>
                        </OfflineFallback>
                      }
                      title={`Management - ${currentTeam}`}
                    />
                  </ErrorBoundary>
                }
              >
                <Route
                  path="teams"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Permission permissionName="teams:visit">
                        <React.Suspense fallback={<CircularLoader />}>
                          <Teams />
                        </React.Suspense>
                      </Permission>
                    </ErrorBoundary>
                  }
                />
                <Route path=":team_id">
                  <Route
                    path="groups"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="groups:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <Groups />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                  }
                  />
                  <Route
                    path="users"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="groups:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <UsersList />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                  }
                  />
                  <Route
                    path="robots"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="groups:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <RobotsList />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                  }
                  />
                </Route>
                <Route
                  path="groups"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Permission permissionName="groups:visit">
                        <React.Suspense fallback={<CircularLoader />}>
                          <Groups />
                        </React.Suspense>
                      </Permission>
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="users"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Permission permissionName="users:visit">
                        <React.Suspense fallback={<CircularLoader />}>
                          <UsersList />
                        </React.Suspense>
                      </Permission>
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="robots"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Permission permissionName="robots:visit">
                        <React.Suspense fallback={<CircularLoader />}>
                          <RobotsList />
                        </React.Suspense>
                      </Permission>
                    </ErrorBoundary>
                  }
                />
              </Route>
              <Route
                path="video"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page title="Video" />
                  </ErrorBoundary>
                }
              />
              <Route
                path="settings"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <OfflineFallback>
                          <React.Suspense fallback={<CircularLoader />}>
                            <Settings />
                          </React.Suspense>
                        </OfflineFallback>
                      }
                      title={`Settings - ${currentTeam}`}
                    />
                  </ErrorBoundary>
                }
              >
                <Route index={true} element={<Navigate to="account" />} />
                <Route
                  path="account"
                  element={
                    <ErrorBoundary theme={theme}>
                      <React.Suspense fallback={<CircularLoader />}>
                        <AccountsTab />
                      </React.Suspense>
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="security"
                  element={
                    <ErrorBoundary theme={theme}>
                      <React.Suspense fallback={<CircularLoader />}>
                        <SecurityTab />
                      </React.Suspense>
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="notifications"
                  element={
                    <ErrorBoundary theme={theme}>
                      <React.Suspense fallback={<CircularLoader />}>
                        <NotificationsTab />
                      </React.Suspense>
                    </ErrorBoundary>
                  }
                />
                <Route
                  path="themes"
                  element={
                    <ErrorBoundary theme={theme}>
                      <React.Suspense fallback={<CircularLoader />}>
                        <ThemesTab />
                      </React.Suspense>
                    </ErrorBoundary>
                  }
                />
              </Route>
              <Route path="/" element={<Navigate to="/robotops" />} />
              <Route
                path="*"
                element={
                  <ErrorBoundary theme={theme}>
                    <Navigate to="/" />
                  </ErrorBoundary>
                }
              />
            </Route>
          </Route>
          <Route
            path="privacy"
            element={
              <ErrorBoundary theme={theme}>
                <Page
                  component={
                    <React.Suspense fallback={<CircularLoader />}>
                      <PrivacyPolicy />
                    </React.Suspense>
                  }
                  title="Privacy Policy"
                />
              </ErrorBoundary>
            }
          />
          <Route path="embed">
            <Route
              path="*"
              element={
                <NotFoundPage theme={theme} />
            }
            />
            <Route
              path="alerts"
              element={
                <ErrorBoundary theme={theme}>
                  <Page
                    component={
                      <OfflineFallback>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Alerts />
                        </React.Suspense>
                      </OfflineFallback>
                        }
                    title="Alerts"
                  />
                </ErrorBoundary>
                  }
              title="Alerts"
            />
            <Route
              path="alerts/:team_id/:alert_id"
              element={
                <ErrorBoundary theme={theme}>
                  <Page
                    component={
                      <OfflineFallback>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Alerts />
                        </React.Suspense>
                      </OfflineFallback>
                      }
                    title="Alerts"
                  />
                </ErrorBoundary>
                }
            />
            <Route
              path="alert/:team_id/:alert_id"
              element={
                <ErrorBoundary theme={theme}>
                  <Page
                    component={
                      <OfflineFallback>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Alert />
                        </React.Suspense>
                      </OfflineFallback>
                      }
                    title="Alert"
                  />
                </ErrorBoundary>
                }
            />
            <Route
              path=":team_code"
              element={
                <ErrorBoundary theme={theme}>
                  <Page
                    component={
                      <React.Suspense fallback={<CircularLoader />}>
                        <VerifyEmbed />
                      </React.Suspense>
                  }
                  />
                </ErrorBoundary>
            }
            >
              <Route
                path=":robot_code"
                element={
                  <ErrorBoundary theme={theme}>
                    <Page
                      component={
                        <Permission permissionName="robotops:visit">
                          <React.Suspense fallback={<CircularLoader />}>
                            <RobotPage
                              defaultFullscreen={true}
                            />
                          </React.Suspense>
                        </Permission>
                          }
                      title="Robot Page"
                    />
                  </ErrorBoundary>
              }
              >
                <Route
                  path="pilot"
                  element={
                    <ErrorBoundary theme={theme}>
                      <OfflineFallback>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Pilot />
                        </React.Suspense>
                      </OfflineFallback>
                    </ErrorBoundary>
                      }
                />
                <Route
                  path="overview"
                  element={
                    <ErrorBoundary theme={theme}>
                      <OfflineFallback>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Overview disableTour={true} defaultFullscreen={true} />
                        </React.Suspense>
                      </OfflineFallback>
                    </ErrorBoundary>
                      }
                />
                <Route
                  path="eventlog"
                  element={
                    <ErrorBoundary theme={theme}>
                      <OfflineFallback>
                        <React.Suspense fallback={<CircularLoader />}>
                          <EventLog defaultFullscreen={true} />
                        </React.Suspense>
                      </OfflineFallback>
                    </ErrorBoundary>
                      }
                />
                <Route
                  path="dataexplorer"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Permission permissionName="data_explorer:visit" isGlobal={false}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <DataExplorer defaultFullscreen={true} />
                        </React.Suspense>
                      </Permission>
                    </ErrorBoundary>
                      }
                />
                <Route
                  path="topic-monitor"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Permission permissionName="topic_monitor:visit" isGlobal={false}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <TopicMonitor defaultFullscreen={true} />
                        </React.Suspense>
                      </Permission>
                    </ErrorBoundary>
                      }
                />
                <Route
                  path="terminal"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Permission permissionName="terminal:visit" isGlobal={false}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Terminal defaultFullscreen={true} />
                        </React.Suspense>
                      </Permission>
                    </ErrorBoundary>
                      }
                />
                <Route
                  path="missions"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Permission permissionName="missions:visit" isGlobal={false}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Missions disableTour={true} defaultFullscreen={true} />
                        </React.Suspense>
                      </Permission>
                    </ErrorBoundary>
                      }
                />
                <Route
                  path="config"
                  element={
                    <ErrorBoundary theme={theme}>
                      <Permission permissionName="config:visit" isGlobal={false}>
                        <React.Suspense fallback={<CircularLoader />}>
                          <Config defaultFullscreen={true} />
                        </React.Suspense>
                      </Permission>
                    </ErrorBoundary>
                      }
                >
                  <Route index={true} element={<RedirectWithQuery to="installation" />} />
                  <Route
                    path="installation"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="installation:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <InstallationTab />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                        }
                  />
                  <Route
                    path="topic-configurator"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="topic_configurator:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <TopicConfiguratorTab />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                        }
                  />
                  <Route
                    path="map"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="config_map:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <MapTab />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                        }
                  />
                  <Route
                    path="properties"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="config_properties:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <PropertiesTab />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                        }
                  />
                  <Route
                    path="camera"
                    element={
                      <ErrorBoundary theme={theme}>
                        <Permission permissionName="camera_setting:visit" isGlobal={false}>
                          <React.Suspense fallback={<CircularLoader />}>
                            <CameraTab />
                          </React.Suspense>
                        </Permission>
                      </ErrorBoundary>
                          }
                  />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route
            path="*"
            element={
              <NotFoundPage theme={theme} />
            }
          />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
