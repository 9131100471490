/* eslint-disable camelcase */
import axios from 'axios';
import store from 'app/store';

import { removeToken, resetAccessToken } from 'pages/auth/authSlice';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import { setLoading, setProgress } from 'components/Loaders/loadingSlice';

// BroadcastChannel for notifying token expiration
const authBroadcast = new BroadcastChannel('auth-channel');

// Use a retryCount to track the number of times we have made a request
// This is done to prevent infinite Error 401 calls during response interceptor
let retryCount = 0;

/* eslint-disable no-underscore-dangle */
const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});
/* eslint-enable no-underscore-dangle */

// Request interceptor
http.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state?.auth?.currentUser?.accessToken;
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    store.dispatch(setLoading(true));
    store.dispatch(setProgress(30));
    return config;
  },
  (error) => {
    store.dispatch(setLoading(false));
    store.dispatch(setProgress(0));
    return Promise.reject(error);
  }
);

// Response interceptor
// In case of unauthorized/401 error returns to login page
// V1
http.interceptors.response.use(
  (response) => {
    store.dispatch(setProgress(100));
    setTimeout(() => {
      // Add a timeout so that user can see progress to 100
      store.dispatch(setLoading(false));
    }, 500);
    return response;
  },
  async (error) => {
    // Note down the original request
    const originalRequest = error.config;

    // Temporary added status code 403 check because expired token error returns code 403 instead of 401
    // need to update it once, backend fixes to status code 401 for expired token
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      if (retryCount > 0) {
        store.dispatch(removeToken());
        store.dispatch(enqueueSnackbar({
          message: 'Session expired, Please login again',
          isClearable: true,
          variant: 'error',
          key: new Date().getTime() + Math.random()
        }));
        retryCount = 0;
      } else {
        const state = store.getState();
        const refreshToken = state?.auth?.currentUser?.refreshToken;
        retryCount += 1;
        if (refreshToken) {
          // Necessary to use refresh token value for access token, in order to call refresh endpoint
          store.dispatch(resetAccessToken(refreshToken));
          try {
            const msg = await http.post(`user/refresh`);
            // If successful refresh, set retryCount back to 0
            // This is because there can be multiple refresh sessions since
            // Refresh token takes a longer time to expire
            retryCount = 0;
            const newAccessToken = msg.data.access_token;
            // After getting new access token from API call, set the new access token
            store.dispatch(resetAccessToken(newAccessToken));
            return http.request(originalRequest);
          } catch (err) {
            return Promise.reject(error);
          }
        } else {
          // Perform handshake to determine if the parent listener is available (current listeners: /robotstreams)
          const hasParentListener = await handshakeWithParentListener();

          if (hasParentListener) {
            // Notify the parent about token expiration since the listener is available
            // Currently only requests launched by iframes in /robotstreams will enter this conditional statement
            authBroadcast.postMessage({ type: 'TOKEN_EXPIRED' });
            await waitForTokenExpiredAck();
          } else {
            // No parent listener, handle token removal and logout independently
            // In our initial release of Smart+, we did not save the user's refresh token
            // Thus, right now we have to handle the case when a refresh token does not exist
            // Currently, we can just direct the user back to the login page
            store.dispatch(removeToken());
            store.dispatch(enqueueSnackbar({
              message: 'Session expired, Please login again',
              isClearable: true,
              variant: 'error',
              key: new Date().getTime() + Math.random()
            }));
          }
          // Set retryCount back to 0 in case user tries to login again in same session
          retryCount = 0;
          store.dispatch(setLoading(false));
          store.dispatch(setProgress(0));
          return Promise.reject(error);
        }
      }
    }
    store.dispatch(setLoading(false));
    store.dispatch(setProgress(0));
    return Promise.reject(error);
  }
);

/**
 * Handshake and token acknowledgment functions for handling communication with the parent listener.
 * These functions are specifically used for `/robotstreams` HTTP requests from iframes to check if a parent
 * listener is available and to handle token expiration events.
 *
 * 1. `handshakeWithParentListener`: This function checks if a parent listener is active by sending a "PING"
 *    message to the parent and waiting for a "PONG" response. It is designed to be used for iframe communication
 *    in the `/robotstreams` context.
 *
 * 2. `waitForTokenExpiredAck`: This function waits for an acknowledgment from the parent listener when a
 *    "TOKEN_EXPIRED" event is triggered. It ensures that the parent has acknowledged the token expiration before
 *    proceeding. The function times out after 1 second to prevent hanging if no acknowledgment is received.
 *
 * These functions are necessary to manage cross-origin communication between the iframe and its parent, primarily
 * to ensure the parent listener is functional and can handle token expiration events appropriately.
 */

// Handshake function to check if parent listener is available
const handshakeWithParentListener = () => new Promise((resolve) => {
  const pingId = `ping-${Date.now()}`;
  let responded = false;

  const handlePong = (event) => {
    if (event.data.type === 'PONG' && event.data.pingId === pingId) {
      responded = true;
      authBroadcast.removeEventListener('message', handlePong);
      resolve(true);
    }
  };

  authBroadcast.addEventListener('message', handlePong);

  // Send a ping to check for parent listener
  authBroadcast.postMessage({ type: 'PING', pingId });

  // Wait for up to 200ms for a "PONG" response
  setTimeout(() => {
    if (!responded) {
      authBroadcast.removeEventListener('message', handlePong);
      resolve(false);
    }
  }, 200);
});

// Wait for token expired acknowledgment from parent listener
const waitForTokenExpiredAck = () => new Promise((resolve) => {
  const handleAck = (event) => {
    if (event.data.type === 'TOKEN_EXPIRED_ACK') {
      authBroadcast.removeEventListener('message', handleAck);
      resolve();
    }
  };

  authBroadcast.addEventListener('message', handleAck);

  // timeout to prevent hanging if no ACK is received.
  setTimeout(() => {
    authBroadcast.removeEventListener('message', handleAck);
    resolve();
  }, 1000);
});

export default http;
