/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import http from 'utils/http';
import { enqueueSnackbar } from 'snackbar/snackbarSlice';
import { oldStatusFilters } from './constants';

/**
 * A redux thunk action creator for verifying otp. It will take in
 * the otp and make the appropriate api call with the appropriate
 * headers and if valid set the new jwt and return the
 * decoded profile from jwt, if invalid just return an object with
 * invalid.
 */
export const getRobots = createAsyncThunk(
  'robotops/getRobots',
  async (params, thunkAPI) => {
    try {
      // eslint-disable-next-line max-len
      params.append('fields', 'robot_id, property_id, status, status_details, robot_code, nick_name, model_id, activation_date,is_active,last_heartbeat_at,  deploy_va_engine, va_engine_status');
      const response = await http.get(`/robot/list`, { params });
      return { ...response, view: params.get('view'), page: params.get('page') };
    } catch (e) {
      if (e.code !== 'ERR_NETWORK') {
        thunkAPI.dispatch(enqueueSnackbar({
          message: e?.response?.data?.message,
          isClearable: true,
          variant: 'error',
          key: new Date().getTime() + Math.random()
        }));
      }
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateRobotConfig = createAsyncThunk(
  'robotops/updateRobotConfig',
  async (params, thunkAPI) => {
    try {
      const res = await http.put(`/team/${params.team_id}/robot/`, params);
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Updated robot configuration',
        isClearable: true,
        variant: 'success',
        key: new Date().getTime() + Math.random()
      }));
      return res;
    } catch (e) {
      thunkAPI.dispatch(enqueueSnackbar({
        message: 'Unable to update robot configuration',
        isClearable: true,
        variant: 'error',
        key: new Date().getTime() + Math.random()
      }));
      return null;
    }
  }
);
/**
* Function to filter and generate valid list of Robots and to
* handle list from being poluted by multiple api calls with same params
*/
const validate = (prevRobotList, newRobotList) => {
  let updatedRobotList = [...prevRobotList];
  const prevRobotsIds = prevRobotList?.map((robots) => (robots.robot_id));
  if (newRobotList?.length && prevRobotsIds?.indexOf(newRobotList[0]?.robot_id) === -1) {
    updatedRobotList = [...prevRobotList, ...newRobotList];
  }
  if (newRobotList?.length === 0) {
    updatedRobotList = [];
  }
  return updatedRobotList;
};

/**
 * The slice that involves authentication, to help track of log in status
 * (whether the user is logged in or in the two factor authentication (tfa)
 * stage etc).
 */
const defaultFilters = {
  view: 'card',
  teamFilter: [],
  groupFilter: [],
  isCompact: false,
  statusFilter: 'active',
  selectedSort: null,
  searchKey: ''
};
const localStorageKey = 'robotFilters';
const localStorageFilters = JSON.parse(localStorage.getItem(localStorageKey));
let initialFilters = defaultFilters;
if (localStorageFilters && !oldStatusFilters.includes(localStorageFilters.statusFilter)) {
  initialFilters = localStorageFilters;
}

const robotopsSlice = createSlice({
  name: 'robotops',
  initialState: {
    pageNumber: 0,
    totalRobotCount: 0,
    totalPages: 0,
    robots: [],
    isRobotsLoading: false,
    robotFilters: initialFilters
  },
  reducers: {
    setRobots(state, action) {
      state.robots = action.payload;
    },
    setRobotFilters(state, action) {
      state.robotFilters = action.payload;
    },
    setRobotList(state, action) {
      state.robots = action.payload;
    },
    removeFilter: (state) => {
      state.robotFilters = initialFilters;
    },
    resetFilter(state) {
      const newFilters = { ...defaultFilters, selectedSort: null, statusFilter: 'all' };
      state.robotFilters = newFilters;
      localStorage.setItem('robotFilters', JSON.stringify(newFilters));
    }
  },
  extraReducers: {
    [getRobots.pending]: (state) => {
      state.isRobotsLoading = true;
    },
    [getRobots.fulfilled]: (state, action) => {
      const { data, view, page } = action.payload;
      state.robots = page === '1' || view === 'table' ? data?.data : validate(state.robots, data?.data);
      state.pageNumber = data?.page_number;
      state.totalRobotCount = data?.result_count;
      state.totalPages = data.max_pages;
      state.isRobotsLoading = false;
    },
    [getRobots.rejected]: (state) => {
      state.isRobotsLoading = false;
    }
  }
});

export const selectRobots = (state) => (state.robotops.robots);
export const selectRobotsLoading = (state) => (state.robotops.isRobotsLoading);
export const selectRobotFilters = (state) => (state.robotops.robotFilters);
export const selectCurrentPage = (state) => (state.robotops.pageNumber);
export const selectTotalRobotCount = (state) => (state.robotops.totalRobotCount);
export const selectTotalPages = (state) => (state.robotops.totalPages);

export const { setRobots, setRobotFilters, setRobotList, removeFilter, resetFilter } = robotopsSlice.actions;

const { reducer } = robotopsSlice;
export default reducer;
